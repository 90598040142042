<template>
  <div class="home">
    <Topbar />
    <Menu />
    <div class="main">
      <Rotation />
      <div class="modecontainer">
        <Mode v-for="item in modeInfo" :key="item.id" v-bind="item" :main-color="mainColor" />
      </div>
      <FriendsLink />
    </div>
    <div class="message" @click="$router.push({name:'Message'})">
      全民<br>环卫
    </div>
    <Footer style="margin-top: 100px" />
  </div>
</template>

<script>
import Topbar from '../../components/topbar/index.vue'
import Menu from '../../components/menu/index.vue'
import Rotation from '../../components/rotation/index.vue'
import Mode from '../../components/mode/index.vue'
import Footer from '../../components/footer/index.vue'
import FriendsLink from '../../components/friendsLink/index'
import { linkImageList, modeList } from '../../utils/api'
export default {
  components: {
    Topbar,
    Menu,
    Rotation,
    Mode,
    Footer,
    FriendsLink
  },
  data() {
    return {
      imageList: [],
      timer: '',
      modeInfo: [],
      picInfo: [],
      mainColor: '',
      dataList: [],
      dataPageNum: 1, // 列表数据加载页码
      dataPageSize: 20, // 列表数据加载每页条数
      dataLoadStop: false, // 列表数据加载停止状态
      dataLoadNomore: false // 列表数据加载是否完成

    }
  },
  created() {
    this.fetchData()

    // for (let i = 1; i < 18; i++) {
    //   this.imageList.push('http://www.360tianma.com/application/cms/static/images/component' + i + '.png')
    // }
  },
  mounted() {
    // this.startTimer()
  },

  methods: {
    loadDataList() {
      // 锁定数据加载状态
      this.dataLoadStop = true

      const requestUrl = this.dataListIntUrl
      this.dataListLoadApi(requestUrl, {
        pid: this.pid,
        cid: this.cid,
        mid: this.mid,
        pagesize: this.dataPageSize,
        pagenum: this.dataPageNum
      })
        .then(function(response) {
          const queryResult = response.data
          const datas = response.data.list
          this.dataList = this.dataList.concat(datas)
          if (queryResult.hasnextpage === 1) {
            this.dataPageNum += 1
            this.dataLoadStop = false
          } else {
            this.dataNomore = true
          }
        }.bind(this))
        .catch(function(error) { // 请求失败处理
          console.log(error)
          this.dataLoadStop = false
        }.bind(this))
    },

    fetchData() {
      modeList().then((res) => {
        this.modeInfo = res.data.data
      })
      linkImageList().then((res) => {
        this.picInfo = res.data.data
        res.data.data.map(v => {
          this.imageList.push(v.imageUrl)
        })
      })
    },

    clearTimer() {
      clearInterval(this.timer)
    },
    startTimer() {
      const content = document.querySelector('.marquee-content')
      this.timer = setInterval(() => {
        const oldleftstr = getComputedStyle(content, null).left
        const oldleft = parseInt(oldleftstr)
        content.style.left = oldleft - 1 + 'px'
        if (oldleft <= -1500) {
          content.style.left = 0
        }
      }, 10)
    }
  }

}
</script>

<style scoped>

.modecontainer {
  width: 1200px;
  margin: 50px auto 0;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.message {
  width: 60px;
  height: 60px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background: linear-gradient(to bottom, #E6D3B6,#88531C);
  position: fixed;
  bottom: 50%;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bolder;
  z-index: 999;
}
</style>
