<template>
  <div v-show="dataList != ''" class="friendsLink">
    <div class="threeImg">
      <div class="Containt">
        <ul :style="{'left':calleft + 'px', width: widthData + 'px'} " class="imgBoxoul" @mouseover="stopmove()" @mouseout="move()">
          <li v-for="(item, index) in dataList" :key="index" ref="lis" @click="gotodetails(item.id)">
            <a :href="item.link" target="_blank"><img :src="item.imageUrl"></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { linkImageList } from '../../utils/api'

export default {
  data() {
    return {
      calleft: 0,
      speed: 1,
      dataList: ''
    }
  },
  computed: {
    widthData() {
      return 240 * Number(this.dataList.length * 2)
    }
  },

  created() {
    this.move()
  },
  mounted() {
    var imgBox = document.getElementsByClassName('imgBoxoul')[0]
    imgBox.innerHTML += imgBox.innerHTML
    linkImageList().then(res => {
      this.dataList = res.data.data
    })
  },
  methods: {
    // 移动
    move() {
      this.timer = setInterval(this.starmove, 20)
    },
    // 开始移动
    starmove() {
      this.calleft -= 1.2//* this.speed
      if (this.calleft <= -650) {
        this.calleft = 0
      }
    },
    // 鼠标悬停时停止移动
    stopmove() {
      clearInterval(this.timer)
    }

  }

}
</script>

<style scoped>
.friendsLink {
  height: 300px;
  padding: 0 20px;
}
.threeImg {
  position: relative;
}

.Containt {
  height: 200px;
}

.threeImg .Containt ul {
  margin: 0 auto;
  width: 1200px;
  position: absolute;
  left: 0px;
  cursor: pointer;
  z-index: 10;
}

.threeImg .Containt ul li {
  float: left;
  width: 220px;
  height: 220px;
  margin-right: 20px;
  overflow: hidden;
  background-color: #ffffff;
}

.threeImg .Containt ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Containt {
  position: relative;
  padding: 60px 0;
  overflow-y: auto;
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
}

</style>
