<template>
  <div class="rotation">
    <el-carousel height="600px">
      <el-carousel-item v-for="item in info" :key="item.id">
        <a :href="item.link" target="_blank">
          <img class="bannerImg" :src="item.imageUrl" alt="加载失败">
        </a>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { rotationList } from '@/utils/api'
export default {
  data() {
    return {
      info: []
    }
  },
  computed: {
    // autoHeight() {
    //   const _w =
    //     document.documentElement.clientWidth || document.body.clientWidth
    //   return (_w * 200) / 450 + 'px'
    // }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      rotationList().then((res) => {
        this.info = res.data.data
      })
    }
  }
}
</script>

<style scoped>
.rotation {
  width: 100%;
}
.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 1200px) {
  .rotation {
    width: 1200px;
  }
  .bannerImg {
    width: 1200px;
  }
}
/deep/.el-carousel__button {
  width: 10px;
  height: 10px;
}
</style>
