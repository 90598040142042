<template>
  <div>
    <div class="topbar">
      <div class="logo">
        <a href="/"><img src="../../assets/images/topbar/yhsy.png" alt="logo"></a>
      </div>
      <div class="search">
        <el-input v-model="searchKey" type="text" @keyup.enter.native="Tomore" />
        <img src="../../assets/images/topbar/search.png" alt="search" @click="Tomore">
      </div>
    </div>
    <!--    <div>-->
    <!--      <div class="message-box">留言板<div />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>
// import { search } from '../../utils/api'
export default {
  data() {
    return {
      searchKey: '',
      info: []
    }
  },
  methods: {
    Tomore() {
      // search({
      //   searchKey: this.searchKey
      // }).then((res) => {
      //   this.info = res.data.data.records
      //   this.$router.push({
      //     path: 'more',
      //     query: {
      //       list: this.info
      //     }
      //   })
      // })
      this.$router.push({
        path: 'more',
        query: {
          propName: 'search',
          searchKey: this.searchKey
        }
      })
    }
  }
}
</script>

<style >
.topbar {
  width: 1200px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 300px;
  height: 40px;
}
.logo img {
  width: 100%;
  height: 100%;
}
.search .el-input__inner {
  border-radius: 20px;
}

.search {
  width: 300px;
  height: 40px;
  border-radius: 20px;
  position: relative;
}
.search > input {
  width: 220px;
  height: 38px;
  outline: none;
  border: none;
  overflow: hidden;
}
.search > img {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
