<template>
  <div class="footer" style="background: #333">
    <div class="footer-main">
      <div class="footer-main-logo">
        <img src="../../assets/images/topbar/yhsy.png" alt="logo">
      </div>
      <div class="footer-main-content">
        <div class="footer-main-content-item">
          <div class="phone">
            <img src="../../assets/images/footer/phone.png" alt="">
            <span>05963800898</span>
          </div>
          <div class="mail">
            <img src="../../assets/images/footer/mail.png" alt="">
            <span>FJYHSY2004@163.com</span>
          </div>
          <div class="qq">
            <img src="../../assets/images/footer/qq.png" alt="qq"><span>757952172</span>
          </div>
        </div>
        <div class="footer-main-content-item">
          <div class="phone">
            <img src="../../assets/images/footer/address.png" alt="">
            <span>福建省漳州市古雷港经济开发区金福路125号、127号</span>
          </div>
        </div>
      </div>
      <div class="footer-main-qrcode">
        <img src="../../assets/images/footer/QRcode.jpg" alt="QRcode">
        <div>欢迎关注琰衡实业公众号</div>
      </div>
    </div>
    <div class="line" />
    <div class="copyright">
      <div>版权所有 © 琰衡实业 | <a class="record" href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2021015039号-1</a></div>
    </div>
  </div>
</template>

<script>
import { mainColor } from '../../utils/api'

export default {
  data() {
    return {
      mainColor: ''
    }
  },
  mounted() {
    mainColor().then(res => {
      this.mainColor = res.data.data.color
    })
  }
}
</script>

<style scoped>
.footer {
  height: 180px;
  width: 100%;
  margin-top: 100px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
}
.footer-main {
  width: 1200px;
  margin: 0 auto ;
  padding-top: 0;
  display: flex;
  position: relative;
}

@media screen and (max-width: 1200px){
  .footer {
    width: 1200px;
  }
  .footer-main {
    width: 1200px;
  }
}
.footer-main-logo {
  width: 330px;
  height: 46px;
  margin-top: 48px;
}
.footer-main-logo > img {
  width: 100%;
  height: 100%;
}
.footer-main-content {
  height: 80px;
  margin: 48px 180px 0 10px;
  padding-left: 20px;
  color: #999999;
}
.footer-main-content-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
}
.phone {
  display: flex;
  align-items: center;
}
.phone > img {
  width: 15px;
  margin-right: 5px;
}
.mail {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.mail > img {
  width: 15px;
  margin-right: 5px;
}

.footer-main-qrcode {
  width: 145px;
  position: absolute;
  top: -80px;
  right: 0;
}
.footer-main-qrcode > img {
  width: 145px;
  height: 145px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.3);
}
.footer-main-qrcode > div {
  font-size: 12px;
  color: #fff;
  opacity: 0.6;
}
.line {
  width: 1220px;
  height: 0;
  margin: 10px auto 0;
  border: 1px solid rgba(255, 255, 255, 0.19);
}
.copyright{
  width: 1220px;
  margin: 10px auto 0;
  display: flex;
  font-size: 12px;
  color: #fff;
  position: relative;
}
.copyright>div{
  position: absolute;
  left:50%;
  transform: translate(-50%);
  opacity: 0.6;
  display: flex;
  align-items: center;
}
.record {
  color: white;
  margin-left: 5px;
}
.qq{
  height:25px;
  font-size: 14px;
  color: #999999;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.qq>img{
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

</style>
