<template>
  <div class="menu" :style="{background: mainColor}">
    <el-menu
      :default-active="activeIndex2"
      mode="horizontal"
      text-color="white"
      :background-color="mainColor"
      active-text-color="white"
    >
      <el-submenu v-for="item in haveChildrenInfo" :key="item.id" :index="item.id" :style="{background: mainColor}" @click.native="handleFatherMenu(item)">
        <template slot="title">{{ item.navigationName }}</template>
        <el-menu-item v-for="child in item.children" :key="child.id" :index="child.id" @click.native="handleChildrenMenu(child)">{{ child.navigationName }}</el-menu-item>
      </el-submenu>
      <el-menu-item v-for="item in noChildrenInfo" :key="item.id" :style="{background: mainColor}" :index="item.id" @click.native="handleNoChildrenMenu(item)">{{ item.navigationName }}</el-menu-item>
    </el-menu>

    <!--    <span v-for="item of info" :key="item.id" @click="toMore(item.id,item.navigationName)">{{ item.navigationName }}</span>-->
  </div>
</template>

<script>
import { navList } from '../../utils/api.js'
import { mainColor } from '../../utils/api'

export default {
  data() {
    return {
      info: [],
      activeIndex: '1',
      activeIndex2: '',
      haveChildrenInfo: [],
      noChildrenInfo: [],
      mainColor: ''
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      navList().then((res) => {
        this.info = res.data.data
        this.haveChildrenInfo = this.info.filter(v => v.children !== null)
        this.noChildrenInfo = this.info.filter(v => v.children === null)
      })
      mainColor().then(res => {
        this.mainColor = res.data.data.color
      })
    },
    handleSelect(key, keyPath) {
      // console.log(key)
      this.toMore(key, key)
    },

    handleFatherMenu(item) {
      this.toMore(item.id, item.navigationName)
    },

    handleChildrenMenu(child) {
      this.toMore(child.id, child.navigationName)
    },

    handleNoChildrenMenu(item) {
      this.toMore(item.id, item.navigationName)
    },

    toMore(id, navigationName) {
      this.$router.push({
        name: 'More',
        query: {
          propName: 'nav',
          name: navigationName,
          id: id
        }
      })
    }
  }
}
</script>

<style scoped>
.menu {
  width: 100%;
}
.el-menu{
  width: 1200px;
  margin: 0 auto;
  font-weight: bolder;
}
.el-menu.el-menu--horizontal{
    border-bottom: none;
}
/deep/.el-submenu__title {
  font-size: 17px;
}
/deep/.el-menu-item {
  font-size: 17px;
}
span{
  display: inline-block;
  padding: 0 20px;
}
</style>
