<template>
  <div class="mode">
    <div class="mode-title">
      <div :style="{borderBottom: '5px solid' + mainColor}">{{ modularName }}</div>
      <div @click="toMore"> > > 更多</div>
    </div>
    <div class="mode-content">
      <div
        v-for="(item, index) of titleList"
        :key="index"
        class="mode-content-item"
        @click="toDetails(item.id)"
      >
        {{ item.articleTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import { mainColor, modeArtTitleList } from '../../utils/api'

export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    modularName: {
      type: String,
      default: ''
    }

  },
  data() {
    return {
      titleList: [],
      mainColor: '',
      listQuery: {
        size: 5,
        current: 1,
        id: this.id
      }
    }
  },
  mounted() {
    // const form = new FormData()
    // form.append('moduleId', this.id)
    modeArtTitleList(this.listQuery).then(res => {
      this.titleList = res.data.data.records.slice(0, 5)
    })
    mainColor().then(res => {
      this.mainColor = res.data.data.color
    })
  },
  methods: {
    toMore() {
      this.$router.push({
        name: 'More',
        query: {
          propName: 'mode',
          name: this.modularName,
          id: this.id
        }
      })
    },
    openNewTab(to) {
      const routeData = this.$router.resolve(to)
      window.open(routeData.href, '_blank')
    },

    toDetails(id) {
      this.openNewTab({
        name: 'Details',
        query: {
          propName: 'mode',
          articleId: id
        }
      })
    }
  }
}
</script>

<style scoped>
.mode {
  width: 350px;
  padding-bottom: 50px;
}
.mode-title {
  display: flex;
  justify-content: space-between;
}
.mode-title > div {
  height: 30px;
  line-height: 30px;
}
.mode-title > div:nth-of-type(1) {
  font-size: 20px;
  font-weight: bold;
  padding: 0 0 10px 0;
}
.mode-title > div:nth-of-type(2) {
  font-size: 14px;
  color: #666666;
  cursor: pointer;
}
.mode-content {
}
.mode-content-item {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  font-weight: bolder;
  border-bottom: 1px solid #bdc3c7;
  cursor: pointer;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.mode-content-item:hover {
  color: #0984e3;
}
</style>
