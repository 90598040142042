import request from './request'

export function navList() {
  return request({
    url: 'navigation/list',
    method: 'get'
  })
}

export function rotationList() {
  return request({
    url: 'rotation/list',
    method: 'get'
  })
}
export function mainColor() {
  return request({
    url: 'Color/ColorInfo/list',
    method: 'get'
  })
}
export function modeList() {
  return request({
    url: 'modular/list',
    method: 'get'
  })
}
export function linkImageList() {
  return request({
    url: 'bottom/list',
    method: 'get'
  })
}
export function search(keyword) {
  return request({
    url: 'moduleArticle/search',
    method: 'get',
    params: {
      keyword
    }
  })
}

export function modeArtTitleList(data) {
  return request({
    url: `moduleArticle/list`,
    method: 'post',
    data
  })
}

export function modeArtContent(data) {
  return request({
    url: `moduleArticle/list`,
    method: 'post',
    data: data
  })
}

export function modeArtDetails(id, current) {
  return request({
    url: `moduleArticle/moduleArticleDetails`,
    method: 'post',
    params: {
      id,
      current
    }
  })
}

// export const PostNavArtTitleAPI = (params) => request.post('articleList/navigationArticleList', params)
// export function navArtTitle(data) {
//   return request({
//     url: `articleList/navigationArticleList`,
//     method: 'post',
//     data: data,
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     }
//   })
// }

export function navArtContent(data) {
  return request({
    url: `navigationArticle/list`,
    method: 'post',
    data: data
  })
}

export const PostNavArtDetailsAPI = (params) => request.post('articleDetails/navigationArticleDetails', params)

export function navArtDetails(id, current) {
  return request({
    url: `navigationArticle/navigationArticleDetails`,
    method: 'post',
    params: {
      id,
      current
    }
  })
}

export function addModCommit(data) {
  return request({
    url: `moduleArticle/comment/add`,
    method: 'post',
    data: data
  })
}

export function getNavCommitList(id) {
  return request({
    url: `navigationArticle/comment/${id}`,
    method: 'get'
  })
}

export function getModCommitList(id) {
  return request({
    url: `moduleArticle/comment/${id}`,
    method: 'get'
  })
}

export function addNavCommit(data) {
  return request({
    url: `navigationArticle/comment/add`,
    method: 'post',
    data: data
  })
}

export function createMessage(data) {
  return request({
    url: `question/raise`,
    method: 'post',
    data: data
  })
}

export function getMessageList(data) {
  return request({
    url: `question/list`,
    method: 'post',
    data: data
  })
}

export function uploadToMinio(data) {
  return request({
    url: 'cos/upload',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data; boundary = __XXXXX_XXXXXX__' },
    data
  })
}

export function uploadToCos(data) {
  return request({
    url: 'cos/upload',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data; boundary = __XXXXX_XXXXXX__' },
    data
  })
}

export function questionList(id) {
  return request({
    url: `question/${id}`,
    method: 'get'
  })
}

export function replyComment(data) {
  return request({
    url: `question/comment/create`,
    method: 'post',
    data
  })
}

export function deleteQuestionComment(id) {
  return request({
    url: `question/comment/delete/${id}`,
    method: 'delete'
  })
}

export function getUserDetail(userId) {
  return request({
    url: `account/detail/${userId}`,
    method: 'get'
  })
}

